<template>
 <div style="height: 100%">
    <el-container style="height: 100%">
      <el-aside  width="">
          <div class="box_bgd"  style="display: flex; justify-content: center; align-items: center;">
          <div class="half-width" @click="isClickCollapse">
            <i class="el-icon-s-fold" v-show="block"></i>
            <i class="el-icon-s-unfold" v-show="toggle"></i>
          </div>
          <div class="half-width" >
            <svg-icon slot="prefix" :icon-class="logoutSvg" @click="logout"/>
          </div>
        </div>
             
        <el-menu
        default-active="0"
          class="el-menu-vertical"
          style="text-align: left"
          background-color="#393e42"
          text-color="#fff"
          active-text-color="rgb(64, 158, 255)"
          :collapse="isCollapse"
        >
          <el-submenu
            v-for="item in menuList"
            :key="item.id"
            :index="item.name"
          >
            <template slot="title">
              <svg-icon slot="prefix" :icon-class="item.meta.icon" />
             
              <span> {{ item.meta.title }}</span>
            </template>
            <el-menu-item
              :index="child.id"
              v-for="child in item.children"
              :key="item.id"
              style="padding-left: 30px"
              @click="selectMenu(item.path, child.path)" >
           
              <template slot="title">
                <svg-icon slot="prefix" :icon-class="child.meta.icon" />
                
                <span> {{ child.meta.title }}</span>
              </template>
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </el-aside>
      <el-container >
        <el-main id ="main">
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import { removeToken } from "@/utils/auth";
import * as Config from "@/settings";
import store from "@/store";
import { Message } from "element-ui";
import Cookies from 'js-cookie'
let user = "";
let user_level ="";
let interface_level = "";
export default {
  name: "Dashboard",
  created() {
    this.getMenus();
    this.logoutSvg = 'logout';
    if(user_level=='3'){
 this.$router.replace('/system/indoorMap').catch((err) => err);
    }else{
 this.$router.replace('/system/config').catch((err) => err);
    }

   
  },

  mounted(){
     
      if(user_level=='3'){
const config = {
            username: user,
            oper: "1",
          }
           console.log('三级下发成功'+JSON.stringify(config, null, 2))
      this.$store.dispatch('SendActOrStopMsgUsername', config).then(res=>{
           console.log('三级下发成功')
          }).catch(()=>{
          })
      }
 
  },
  methods: {
    getMenus() {
      // this.$request.get('api/menus/build').then(res => {
      //   this.menuList = res
      //    console.log(this.menuList);
      // })
      user =  Cookies.get('user');
      user_level=  Cookies.get('user_level');
      interface_level =  Cookies.get('interface_level');
      
      const menuListLevel = [
        {
          name: "System",
          path: "/system",
          hidden: false,
          redirect: "noRedirect",
          component: "Layout",
          alwaysShow: true,
          meta: {
            title: "系统管理",
            icon: "system",
            noCache: false,
            link: null,
          },
          children: [
          ],
        },
      ];
      const config ={
              name: "Config",
              path: "config",
              hidden: false,
              component: "system/config/index",
              id: "0",
              meta: {
                title: "配置管理",
                icon: "role",
                noCache: false,
                link: null,
              },
            };
      const indoorMap  = {
              name: "IndoorMap",
              path: "indoorMap",
              hidden: false,
              component: "system/indoorMap/index",
              id: "1",
              meta: {
                title: "室内地图",
                icon: "user",
                noCache: false,
                link: null,
              },
            };      
      const indoorTrack = {
              name: "IndoorTrack",
              path: "indoorTrack",
              hidden: false,
              component: "system/indoorTrack/index",
              id: "2",
              meta: {
                title: "室内轨迹",
                icon: "user",
                noCache: false,
                link: null,
              },
            };  
      const outdoorMap ={
              name: "OutdoorMap",
              path: "outdoorMap",
              hidden: false,
              component: "system/outdoorMap/index",
              id: "3",
              meta: {
                title: "室外地图",
                icon: "user",
                noCache: false,
                link: null,
              },
            };
      const outdoorTrack ={
              name: "OutdoorTrack",
              path: "outdoorTrack",
              hidden: false,
              component: "system/outdoorTrack/index",
              id: "4",
              meta: {
                title: "室外轨迹",
                icon: "user",
                noCache: false,
                link: null,
              },
            };
      const locationGrid ={
              name: "LocationGrid",
              path: "locationGrid",
              hidden: false,
              component: "system/locationGrid/index",
              id: "5",
              meta: {
                title: "网格地图",
                icon: "user",
                noCache: false,
                link: null,
              },
            };
     const menuListAdmin=[
        {
          name: "System",
          path: "/system",
          hidden: false,
          redirect: "noRedirect",
          component: "Layout",
          alwaysShow: true,
          meta: {
            title: "系统管理",
            icon: "system",
            noCache: false,
            link: null,
          },
          children: [
            {
              name: "Config",
              path: "config",
              hidden: true,
              component: "system/config/index",
              id: "0",
              meta: {
                title: "配置管理",
                icon: "role",
                noCache: false,
                link: null,
              },
            },
             {
              name: "IndoorMap",
              path: "indoorMap",
              hidden: false,
              component: "system/indoorMap/index",
              id: "1",
              meta: {
                title: "室内地图",
                icon: "user",
                noCache: false,
                link: null,
              },
            }, 
           {
              name: "IndoorTrack",
              path: "indoorTrack",
              hidden: false,
              component: "system/indoorTrack/index",
              id: "2",
              meta: {
                title: "室内轨迹",
                icon: "user",
                noCache: false,
                link: null,
              },
            },
            {
              name: "OutdoorMap",
              path: "outdoorMap",
              hidden: false,
              component: "system/outdoorMap/index",
              id: "3",
              meta: {
                title: "室外地图",
                icon: "user",
                noCache: false,
                link: null,
              },
            },
            
            {
              name: "OutdoorTrack",
              path: "outdoorTrack",
              hidden: false,
              component: "system/outdoorTrack/index",
              id: "4",
              meta: {
                title: "室外轨迹",
                icon: "user",
                noCache: false,
                link: null,
              },
            },
            // {
            //   name: "LocationGrid",
            //   path: "locationGrid",
            //   hidden: false,
            //   component: "system/locationGrid/index",
            //   id: "5",
            //   meta: {
            //     title: "网格地图",
            //     icon: "user",
            //     noCache: false,
            //     link: null,
            //   },
            // },
          ],
        },
      ];
      if(user == 'admin'){
       this.menuList = menuListAdmin;
      }else if(user_level == '2' ){
        if(interface_level == '1'){
         menuListLevel[0].children.push(config);  
         menuListLevel[0].children.push(outdoorMap);  
         menuListLevel[0].children.push(outdoorTrack);  
        }else{
         menuListLevel[0].children.push(config);  
         menuListLevel[0].children.push(indoorMap);  
         menuListLevel[0].children.push(indoorTrack); 
        }
       
      
      }else if(user_level == '3' ){
        if(interface_level == '1'){
        //  menuListLevel[0].children.push(config);  
         menuListLevel[0].children.push(outdoorMap);  
         menuListLevel[0].children.push(outdoorTrack);  
        }else{
        //  menuListLevel[0].children.push(config);  
         menuListLevel[0].children.push(indoorMap);  
         menuListLevel[0].children.push(indoorTrack); 
        }
       // 在设置完menuListLevel[0].children之后
      let currentId = 0;
       menuListLevel[0].children.forEach((child) => {
       child.id = currentId+"";
      currentId++;
})
      this.menuList = menuListLevel;
      }
     
    },
    logout() {
      this.$store.dispatch("LogOut").then(() => {
        this.$router.replace("/login");
        Cookies.remove('user');
        Cookies.remove('user_level')
        Cookies.remove('interface_level')
        removeToken(Config.TokenKey);
      });
    },
    selectMenu(path1, path2) {
      // 在这里根据 path1 和 path2 设置 shouldShowHeader 的值
      console.log(path1+','+path2);
      // 继续处理路由或其他操作
      this.$router.replace(path1 + '/' + path2).catch((err) => err);
    },

     // 动态控制展开与收起和切换对应图标
  isClickCollapse() {
    this.isCollapse = !this.isCollapse;
    this.toggle = !this.toggle;
    this.block = !this.block;
  },
  },
  data() {
    return {
      tableData: [
      ],
      multipleSelection: [],
      menuList: [
      ],
      isCollapse: false, //导航栏默认为展开
      toggle: false,//第二个图标默认隐藏
      block: true,//默认显示第一个图标
      logoutSvg:'logout',
    };
  },
};
</script>

<style scoped>

#main {
  width: 100%;
  height: 100vh; /* Occupy the full viewport height */
  margin: 0;
  padding: 0;
}

.el-aside {
  background-color: #393e42;
  color: #333;
  height: 100vh;

}

.el-menu {
  border-right-width: 0;
}
.el-main {
  color: #333;
}

.el-menu-vertical:not(.el-menu--collapse) {
  width: 160px;
}


.box_bgd {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 100%; /* Occupies full width */
}

.half-width {
  flex: 1; /* Each child will take an equal amount of space */
  text-align: center; /* Optional: Align content in the center */
}
.box_bgd i {
  font-size: 20px;
  color: rgba(250, 246, 246, 0.874);
}
.box_bgd:hover {
  background-color:  #333;
  color: rgba(250, 246, 246, 0.874);
}
</style>
